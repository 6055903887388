<template>
  <div>
    <v-alert v-if="!survey.enabled_voting" type="warning" prominent>Hlasovanie bolo zastavené</v-alert>

    <v-btn
      elevation="2"
      fab
      absolute
      small
      @click="$router.go(-1)"
      v-show="!$vuetify.breakpoint.smAndDown"
    ><v-icon color="primary">mdi-arrow-left-bold</v-icon></v-btn>

    <v-row dense>
      <v-col cols="1"></v-col>
      <v-col
        cols="10"
        align="center"
      >
        <span class="text-h4">
          {{ survey.name }}
        </span>
      </v-col>
      <v-col cols="1">
        <share-component
          :name="survey.name"
          :pageUrl="pageUrl"
        ></share-component>
      </v-col>
    </v-row>
    <v-row
      dense
      v-show="survey.sub_name"
    >
      <v-col align="center">
        <span class="text-subtitle">
          {{ survey.sub_name }}
        </span>
      </v-col>
    </v-row>

    <v-row
      v-if="!$vuetify.breakpoint.smAndDown"
      no-gutters
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card-subtitle>
          <v-row dense>
            <v-col cols="auto">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="margin-bottom: 2px;"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <v-card><v-card-text>Počet oprávnených voličov je číslo, ktoré je presne vyčísliteľné len v deň volieb,
                    pretože je síce jasné koľko osôb bude mať v deň konania volieb 18 rokov (dosiahnutý vek potrebný na
                    získanie voličského oprávnenia) ale v čase pred dňom konania volieb nie je známe koľko ľudí umrie do
                    dňa konania volieb. Preto tu uvádzame približné číslo, ktoré potrebujeme pre ďalšie nasledovné
                    výpočty.</v-card-text></v-card>
              </v-menu>

              &nbsp;Počet oprávnených voličov v SR:
              <strong>{{ survey.total_possible_everyone.toLocaleString('sk-SK') }}</strong>
            </v-col>

          </v-row>
          <v-row dense>
            <v-col cols="auto">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="margin-bottom: 2px;"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <v-card><v-card-text>Počet hlasujúcich celkom a ich podiel ku všetkým zaregistrovaným potenciálnym
                    hlasujúcim na tejto platforme, ktorí majú oprávnenie hlasovať – teda inak povedané, je to naša účasť
                    na tomto prieskume predvolebných preferencií.</v-card-text></v-card>
              </v-menu>

              &nbsp;Hlasovali celkom: <strong>{{ survey.total_votes_count }} ({{ Math.round(survey.total_votes_percentage
                * 100) / 100 }}%)</strong>

            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="margin-bottom: 2px;"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <v-card><v-card-text>Toto číslo je podiel zaregistrovaných potenciálnych hlasujúcich na tejto stránke a
                    počtom oprávnených voličov v SR.</v-card-text></v-card>
              </v-menu>

              &nbsp;Prienik voličov: <strong>{{ Math.round(survey.possible_voters_count / survey.total_possible_everyone *
                100 * 100000 + Number.EPSILON) / 100000 }}%</strong>

            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="auto">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="margin-bottom: 2px;"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <v-card><v-card-text>V reálnej skutočnosti je toto číslo počet oprávnených voličov.</v-card-text></v-card>
              </v-menu>

              &nbsp;Počet zaregistrovaných potenciálnych hlasujúcich:
              <strong>{{ survey.possible_voters_count.toLocaleString('sk-SK') }}</strong>

            </v-col>
          </v-row>

        </v-card-subtitle>

        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Poradie
                  </th>
                  <th class="text-left">
                    <span v-if="survey.type == 'STRANA'">Logo</span>
                    <span v-else-if="survey.type == 'CLOVEK'">Foto</span>
                    <span v-else-if="survey.type == 'INE'">Obrázok</span>
                  </th>
                  <th class="text-left">
                    <span v-if="survey.type == 'STRANA'">Názov strany/hnutia</span>
                    <span v-else-if="survey.type == 'CLOVEK'">Meno a priezvisko</span>
                    <span v-else-if="survey.type == 'INE'">Značka</span>
                  </th>
                  <th class="text-left">
                    Počet hlasov
                  </th>
                  <th class="text-left">
                    Percentá
                  </th>
                  <th class="text-left">
                    Graf
                  </th>
                  <th
                    v-if="survey.type == 'STRANA'"
                    class="text-left"
                  >
                    Počet kresiel
                  </th>
                  <th v-if="survey.enabled_discussion" class="text-left">
                    DISKUSIA
                  </th>
                  <th
                    class="text-left"
                    width="10%"
                  >
                    MOJ HLAS
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in survey.parties"
                  :key="item.id"
                >
                  <td>{{ item.rank }}</td>
                  <td>
                    <v-img
                      :src="item.logo"
                      max-width="50"
                      max-height="50"
                      style="cursor: pointer;"
                      @click="overlay = item.logo"
                    ></v-img>
                  </td>
                  <td>{{ item.name }}</td>
                  <td><strong>{{ item.votes_count.toLocaleString('sk-SK') }}</strong></td>
                  <td><strong>{{ Math.round((item.votes_percentage + Number.EPSILON) * 100) / 100 }}%</strong></td>
                  <td width="20%">
                    <v-progress-linear
                      :value="Math.round((item.votes_percentage + Number.EPSILON) * 100) / 100"
                      :color="item.color"
                      height="30"
                      light
                      rounded
                      background-color="white"
                      :style="getBorderStyle(item.color)"
                    >
                    </v-progress-linear>
                  </td>
                  <td v-if="survey.type == 'STRANA'">{{ Math.round(item.chairs_count + Number.EPSILON) }}</td>
                  <td v-if="survey.enabled_discussion">
                    <v-btn
                      small
                      text
                      color="primary"
                      :to="'/survey_discussion/' + survey.id + '/' + item.id"
                    >
                      Vstúpiť ({{item.comments_count}})
                    </v-btn>
                  </td>

                  <td class="text-center">
                    <div v-if="isAuthenticated && isVerifiedSmsEmail">
                      <div v-if="item.user_voted || item.can_user_vote">

                        <v-btn
                          v-if="!item.user_voted"
                          color="success"
                          @click="vote(item, survey.id)"
                        >DAŤ</v-btn>
                        <v-btn
                          v-else
                          color="error"
                          @click="unvote(item, survey.id)"
                        >ODOBRAŤ</v-btn>
                      </div>
                      <div v-else>
                        <v-btn
                          v-if="!item.user_voted"
                          disabled
                          color="success"
                        >DAŤ</v-btn>
                      </div>
                    </div>
                    <div v-else>
                      <fake-button
                        color="success"
                        text="DAŤ"
                      ></fake-button>
                    </div>
                  </td>
                </tr>

                <tr
                  v-if="survey.type == 'STRANA'"
                  style="box-shadow: 0 2px 0 #000 inset;"
                >
                  <td></td>
                  <td class="pl-7">
                    <v-icon color="primary">mdi-emoticon-sad-outline</v-icon>
                  </td>
                  <td>POD 2%</td>
                  <td>{{ survey.total_votes_bellow_2_count }}</td>
                  <td>{{ Math.round((survey.total_votes_bellow_2_percentage + Number.EPSILON) * 100) / 100 }}%</td>
                  <td width="20%">
                    <v-progress-linear
                      :value="Math.round(survey.bellow_2_chairs + Number.EPSILON)"
                      color="black"
                      height="30"
                      light
                      rounded
                      background-color="white"
                      style="border-style: solid; border-width: 1px;border-color: black"
                    >
                    </v-progress-linear>
                  </td>
                  <td>{{ Math.round(survey.bellow_2_chairs + Number.EPSILON) }}</td>

                  <td class="text-center">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <v-card><v-card-text>Tu sú spolu spočítané hlasy strán a hnutí, ktoré by nedosiahli ani 2 % a teda
                          by prepadli. Tieto hlasy sa nikam nezapočítavajú lebo už sú započítane v riadnom prieskume nad
                          touto hrubou čiarou. Slúžia len ako zaujímavá informácia.</v-card-text></v-card>
                    </v-menu>
                  </td>
                </tr>
                <tr v-if="survey.type == 'STRANA'">
                  <td></td>
                  <td class="pl-7">
                    <v-icon color="primary">mdi-emoticon-sad-outline</v-icon>
                  </td>
                  <td>POD 5%</td>
                  <td>{{ survey.total_votes_bellow_5_count }}</td>
                  <td>{{ Math.round((survey.total_votes_bellow_5_percentage + Number.EPSILON) * 100) / 100 }}%</td>
                  <td width="20%">
                    <v-progress-linear
                      :value="Math.round(survey.bellow_5_chairs + Number.EPSILON)"
                      color="black"
                      height="30"
                      light
                      rounded
                      background-color="white"
                      style="border-style: solid; border-width: 1px;border-color: black"
                    >
                    </v-progress-linear>
                  </td>
                  <td>{{ Math.round(survey.bellow_5_chairs + Number.EPSILON) }}</td>

                  <td class="text-center">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          color="primary"
                          dark
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-information-outline
                        </v-icon>
                      </template>
                      <v-card><v-card-text>Tu sú spolu spočítané hlasy strán a hnutí, ktoré by nedosiahli ani 5 % a teda
                          by prepadli. Tieto hlasy sa nikam nezapočítavajú lebo už sú započítane v riadnom prieskume nad
                          touto hrubou čiarou. Slúžia len ako zaujímavá informácia.</v-card-text></v-card>
                    </v-menu>
                  </td>
                </tr>

              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

      </v-col>
    </v-row>
    <v-row
      v-else
      no-gutters
    >
      <v-card-subtitle>
        <v-row dense>
          <v-col cols="auto">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  style="margin-bottom: 2px;"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <v-card><v-card-text>Počet oprávnených voličov je číslo, ktoré je presne vyčísliteľné len v deň volieb,
                  pretože je síce jasné koľko osôb bude mať v deň konania volieb 18 rokov (dosiahnutý vek potrebný na
                  získanie voličského oprávnenia) ale v čase pred dňom konania volieb nie je známe koľko ľudí umrie do dňa
                  konania volieb. Preto tu uvádzame približné číslo, ktoré potrebujeme pre ďalšie nasledovné
                  výpočty.</v-card-text></v-card>
            </v-menu>

            &nbsp;Počet oprávnených voličov v SR:
            <strong>{{ survey.total_possible_everyone.toLocaleString('sk-SK') }}</strong>
          </v-col>

        </v-row>
        <v-row dense>
          <v-col cols="auto">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  style="margin-bottom: 2px;"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <v-card><v-card-text>Počet hlasujúcich celkom a ich podiel ku všetkým zaregistrovaným potenciálnym
                  hlasujúcim na tejto platforme, ktorí majú oprávnenie hlasovať – teda inak povedané, je to naša účasť na
                  tomto prieskume predvolebných preferencií.</v-card-text></v-card>
            </v-menu>

            &nbsp;Hlasovali celkom: <strong>{{ survey.total_votes_count }} ({{ Math.round(survey.total_votes_percentage *
              100)
              / 100 }}%)</strong>

          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="auto">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  style="margin-bottom: 2px;"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <v-card><v-card-text>Toto číslo je podiel zaregistrovaných potenciálnych hlasujúcich na tejto stránke a
                  počtom oprávnených voličov v SR.</v-card-text></v-card>
            </v-menu>

            &nbsp;Prienik voličov: <strong>{{ Math.round(survey.possible_voters_count / survey.total_possible_everyone *
              100 * 100000 + Number.EPSILON) / 100000 }}%</strong>

          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="auto">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  style="margin-bottom: 2px;"
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <v-card><v-card-text>V reálnej skutočnosti je toto číslo počet oprávnených voličov.</v-card-text></v-card>
            </v-menu>

            &nbsp;Počet zaregistrovaných potenciálnych hlasujúcich:
            <strong>{{ survey.possible_voters_count.toLocaleString('sk-SK') }}</strong>

          </v-col>
        </v-row>

      </v-card-subtitle>

      <v-card-text>
        <v-card
          v-for="item in survey.parties"
          :key="item.id"
          class="mb-2"
        >
          <v-card-title>
            <v-row justify="space-between">
              <v-col cols="auto">

                {{ item.rank }}. {{ item.name }}
              </v-col>
              <v-col cols="auto">
                <v-img
                  :src="item.logo"
                  max-width="50"
                  max-height="50"
                  style="cursor: pointer;"
                  @click="overlay = item.logo"
                ></v-img>
              </v-col>
            </v-row></v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col cols="auto">
                Počet hlasov: {{ item.votes_count.toLocaleString('sk-SK') }} (<strong>{{ Math.round((item.votes_percentage
                  + Number.EPSILON) * 100) / 100 }}%</strong>)<span v-if="item.type == 'STRANA'"> - počet kresiel: {{
    Math.round(item.chairs_count +
      Number.EPSILON) }}</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>

                <v-progress-linear
                  :value="Math.round((item.votes_percentage + Number.EPSILON) * 100) / 100"
                  :color="item.color"
                  height="20"
                  light
                  rounded
                  background-color="white"
                  :style="getBorderStyle(item.color)"
                >
                </v-progress-linear>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="auto">
                <div v-if="isAuthenticated && isVerifiedSmsEmail">
                  <div v-if="item.user_voted || item.can_user_vote">

                    <v-btn
                      v-if="!item.user_voted"
                      color="success"
                      @click="vote(item, survey.id)"
                    >DAŤ</v-btn>
                    <v-btn
                      v-else
                      color="error"
                      @click="unvote(item, survey.id)"
                    >ODOBRAŤ</v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      v-if="!item.user_voted"
                      disabled
                      color="success"
                    >DAŤ</v-btn>
                  </div>
                </div>
                <div v-else>
                  <fake-button
                    color="success"
                    text="DAŤ"
                  ></fake-button>
                </div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="auto">
                <v-btn
                  small
                  text
                  color="primary"
                  :to="'/survey_discussion/' + survey.id + '/' + item.id"
                >
                  Vstúpiť do diskusie ({{item.comments_count}})
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>

      <p>Táto stránka na verzii pre počítače obsahuje viacej informácií a je prehľadnejšia.</p>
    </v-row>

    <v-overlay
      v-show="overlay"
      @click="overlay = null"
    >
      <v-img
        :src="overlay"
        contain
        max-width="250"
        max-height="250"
        style="cursor: pointer;"
      ></v-img>
    </v-overlay>

  </div>
</template>
  
<script>
import Vue from "vue";

import { APIService } from "@/http/APIService.js";
import { mapGetters } from "vuex";
import FakeButton from "@/components/FakeButton.vue"
import ShareComponent from "../components/ShareComponent.vue";

const apiService = new APIService();

Vue.use(require("vue-moment"));

export default {
  components: {
    "fake-button": FakeButton,
    ShareComponent
  },

  data: () => ({
    overlay: null,
    loading: false,

    survey: {},

    headersReferendumArchive: [
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Názov",
        value: "name"
      },
      { text: "Začiatok", value: "start_date" },
      { text: "Koniec", value: "end_date" },
    ],
  }),

  mounted: function () {
    this.getSurvey();
  },

  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "username",
      "isVerified",
      "isVerifiedSmsEmail",
      "isVerifiedBank",
      "isStaff",
      "getMemberStatus",
      "firstName",
      "lastName",
      "gender"
    ]),

    getMobileClasses() {
      if (this.$vuetify.breakpoint.smAndDown)
        return "d-flex justify-center align-center";
      else
        return ""
    },

    surveyID() {
      return parseInt(this.$route.params.surveyID);
    },

    pageUrl() {
      return encodeURI(window.location.origin + this.$route.path);
    },
  },

  methods: {
    vote(item, survey) {
      apiService.voteSurveyParty(item.id, survey).then(() => {
        this.getSurvey();
      });
    },

    unvote(item, survey) {
      apiService.unvoteSurveyParty(item.id, survey).then(() => {
        this.getSurvey();
      });
    },


    getBorderStyle(color) {
      return `border-style: solid; border-width: 1px;border-color: ${color}`;
    },
    itemRowBackgroundFunc() {
      return "";
    },

    getSurvey() {
      apiService.getSurvey(this.surveyID).then((response) => {
        this.survey = response;
        this.survey.parties.sort(function (a, b) {
          return b.votes_count - a.votes_count;
        });
      });
    },
  },
};
</script>